import { CdkDragDrop, DragDropModule, transferArrayItem } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
    Component,
    DestroyRef,
    Injector,
    Signal,
    WritableSignal,
    computed,
    effect,
    input,
    signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import {
    Area,
    OrganisationStructure,
    OrganisationSubType,
    System,
    UploadFileService,
} from 'app/api';
import { OrganisationFilterService } from 'app/layout/common/organisation-filter/organisation-filter.service';
import { EmployeeService } from 'app/modules/employees/employee.service';
import { GridViewsModule, TableColumnDef } from '../../../../../projects/grid-views/src/public-api';
import { OrgSysAreaFormService } from '../org-sys-area.form.service';
import { OrgSysAreaService } from '../org-sys-area.service';
import { AreaService } from '../services/area.service';
import { OrgService } from '../services/org.service';
import { SysService } from '../services/sys.service';
import { DraggableListComponent } from './draggable-list/draggable-list.component';
import { SaveButtonService } from 'app/core/save-button/save-button.service';
import { ApiUploadFilePost200Response } from 'app/api/model/apiUploadFilePost200Response';
import { DragDropDirective } from 'app/modules/employees/inventory/list/image-upload/drag-drop.directive';

@Component({
    selector: 'org-sys-area-editor',
    imports: [],
    templateUrl: './org-sys-area-editor.component.html',
    styleUrl: './org-sys-area-editor.component.scss',
})
export abstract class OrgSysAreaEditorComponent<T> {
    inDevelopmentStatus = input(true);
    selectedItem: Signal<T>;

    form = new FormGroup({});
    model: Signal<any>;
    fields: Signal<FormlyFieldConfig[]>;

    showHeritants: WritableSignal<boolean> = signal(false);
    showLogo: WritableSignal<boolean> = signal(false);

    translocoContent: WritableSignal<any> = signal(null);

    displayedColumns: Signal<TableColumnDef[]>;
    dataSource: Signal<MatTableDataSource<any>>;
    selection: Signal<any>;

    protected _service: OrgSysAreaService<T, any>;
    protected _formService: OrgSysAreaFormService<T>;
    protected _destroyRef: DestroyRef;
    protected _translocoService: TranslocoService;

    constructor(protected inject: Injector) {
        this.injectServices();

        this._translocoService
            .selectTranslation('bpOrganisation')
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(content => {
                this.translocoContent.set(content);
            });

        effect(() => {
            if (this.fields()) {
                let editable = this._formService.editableForm();
                if (!this.form) return;
                setTimeout(() => {
                    if (editable) this.form.enable();
                    else this.form.disable();
                });
            }
        });
    }

    protected addAdditionalField(fields: FormlyFieldConfig[]): void {}

    rowSelectionChange($event: Array<any>): void {}

    handleSelectedRows(selectedOrgs: any[]): void {}

    protected defaultConstructor(): void {
        this._formService.service = this._service;

        this.selectedItem = computed(this._service.selectedItem);
        this.model = computed(() => this.constructModel());

        this.form.disable();
        this.fields = computed(() => {
            const fields = this._formService.formFields();

            this.addAdditionalField(fields);
            return fields;
        });

        this.form.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(value => {
            if (this.form.disabled) return;

            this._formService.validForm = this.form.valid;
            this._formService.changedFormValue = { ...this.form.getRawValue() };
        });
    }

    private injectServices(): void {
        this._formService = this.inject.get(OrgSysAreaFormService);
        this._destroyRef = this.inject.get(DestroyRef);
        this._translocoService = this.inject.get(TranslocoService);
    }

    protected abstract constructModel(): void;

    onFileDropped(fileList: FileList) {
        this.handleFileInput(fileList);
    }

    protected handleFileInput(files: FileList) {}

    onFileSelected(event: any) {
        const files: FileList = event.target.files;
        this.handleFileInput(files);
    }
}

const commonImports = [
    CommonModule,
    TranslocoModule,
    FuseAlertComponent,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    FormlyModule,
    MatTabsModule,
    GridViewsModule,
    DragDropDirective,
];

@Component({
    selector: 'org-sys-area-editor',
    imports: [...commonImports],
    templateUrl: './org-sys-area-editor.component.html',
    styleUrl: './org-sys-area-editor.component.scss',
})
export class OrgEditorComponent extends OrgSysAreaEditorComponent<OrganisationStructure> {
    constructor(protected inject: Injector) {
        super(inject);

        this._service = this.inject.get(OrgService);

        this.defaultConstructor();
    }

    constructModel() {
        let selectedOrg = this.selectedItem();
        if (!selectedOrg) return;

        let model: any = {
            title: selectedOrg.title,
        };

        if (selectedOrg.hasOwnProperty('type')) {
            model.infoTypes = (selectedOrg as OrganisationSubType).infoTypes;
        } else {
            model.version = (selectedOrg as OrganisationStructure).version;
            model.status = (selectedOrg as OrganisationStructure).status;
        }
        model.code = (selectedOrg as OrganisationStructure).code;

        return model;
    }
}

@Component({
    selector: 'org-sys-area-editor',
    imports: [...commonImports, DragDropModule, MatPaginatorModule, DraggableListComponent],
    templateUrl: './org-2-editor.component.html',
    styleUrl: './org-sys-area-editor.component.scss',
})
export class OrgEditor2Component extends OrgSysAreaEditorComponent<OrganisationStructure> {
    private originalTodo: OrganisationSubType[] = [];
    todo: WritableSignal<OrganisationSubType[]> = signal([]);
    private _shownTodo = computed(() => {
        return this.todo().slice(
            this.pageIndex() * this.pageSize(),
            this.pageIndex() * this.pageSize() + this.pageSize()
        );
    });

    public get shownTodo() {
        return this._shownTodo();
    }

    done: OrganisationSubType[] = [];

    filterForm = new FormGroup({});
    filterModel = {
        filter: '',
    };
    filterFields: FormlyFieldConfig[] = [
        {
            key: 'filter',
            type: 'input',
            templateOptions: {
                label: 'Filter List',
                placeholder: 'Type to filter...',
            },
            expressionProperties: {
                'model.filter': (model: any, formState: any, field: FormlyFieldConfig) => {
                    this.filterList(field.formControl.value);
                },
            },
        },
    ];

    constructor(
        protected inject: Injector,
        private employeeService: EmployeeService
    ) {
        super(inject);
        this._service = this.inject.get(OrgService);

        effect(() => {
            if (this.selectedItem()) {
                let versionId = this.selectedItem().versionId;
                if (!versionId) {
                    versionId = this.selectedItem().id.split('_')[0];
                }
                this.employeeService.selectedVersion = versionId;
                this.employeeService.availableEmployees.subscribe(employees => {
                    let pictureNumber = 0;
                    const maxNumber = 3;
                    this.originalTodo = employees.map(employee => {
                        pictureNumber++;
                        if (pictureNumber > maxNumber) {
                            pictureNumber = 1;
                        }

                        // employee.metadata = `user_picture_${pictureNumber}`;
                        return {
                            id: `${employee.employee_id}`,
                            title: `${employee.first} ${employee.last}`,
                            metadata: {
                                roles: employee.roles,
                                picture: employee.metadata,
                            },
                        };
                    });
                    this.done = this.selectedItem().metadata?.['employees'] ?? [];
                    this.todo.set(
                        this.originalTodo.filter(
                            orgSubType => !this.done.some(x => x.id === orgSubType.id)
                        )
                    );
                });
            }
        });

        this.defaultConstructor();
    }

    // Function to filter the list
    filterValue: string;
    filterList(filterValue: string) {
        this.filterValue = filterValue;
        // const filterValue = this.filterModel.filter.toLowerCase();
        if (!filterValue) {
            this.todo.set(this.originalTodo.filter(item => !this.done.includes(item)));
        } else {
            this.pageIndex.set(0);
            this.todo.set(
                this.originalTodo.filter(
                    item =>
                        item.title.toLowerCase().includes(filterValue) && !this.done.includes(item)
                )
            );
        }
    }

    constructModel() {
        return this.selectedItem();
    }

    paginatedList: any[];
    drop(drop: { event: CdkDragDrop<OrganisationSubType[]>; pageSize: number; pageIndex: number }) {
        if (!this.inDevelopmentStatus) {
            return;
        }
        const event = drop.event;
        const pageSize = drop.pageSize;
        const pageIndex = drop.pageIndex;

        const item = this.paginatedList[event.previousIndex];
        const previousIndex = event.previousContainer.data.findIndex(i => i.id === item.id);

        if (event.previousContainer !== event.container) {
            // Transfer between lists

            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                previousIndex,
                event.currentIndex + pageSize * pageIndex
            );

            // Re-filter the `todo` list after the transfer
            if (!this.filterValue) {
                this.todo.set(
                    this.originalTodo.filter(item => !this.done.some(x => x.id === item.id))
                );
            } else {
                this.todo.set(
                    this.originalTodo.filter(
                        item =>
                            item.title.toLowerCase().includes(this.filterValue.toLowerCase()) &&
                            !this.done.some(x => x.id === item.id)
                    )
                );
            }

            this.setData();
        }
    }

    exited(paginatedList: any[]) {
        this.paginatedList = paginatedList;
    }

    async setData(): Promise<void> {
        if (this._service.selectedItem()) {
            let selectedId: string;
            if (this._service.selectedItem().id.includes('_')) {
                selectedId = this._service.selectedItem().id.split('_')[1];
            } else {
                selectedId = this._service.selectedItem().id;
            }
            const allEmployees = await this.employeeService.availableEmployees.toPromise();

            const employees = this.done.map(item => {
                return { ...allEmployees.find(x => x.employee_id === item.id) };
            });

            const employeesToRemove = allEmployees.filter(
                employee => !employees.includes(employee) && employee.roles?.includes(selectedId)
            );

            await Promise.all(
                employees.map(async employee => {
                    if (employee.roles) {
                        employee.roles.push(selectedId);
                    } else {
                        employee.roles = [selectedId];
                    }
                    await this.employeeService.updateEmployee2(employee).toPromise();
                })
            );

            await Promise.all(
                employeesToRemove.map(async employee => {
                    employee.roles = employee.roles.filter(x => x !== selectedId);

                    await this.employeeService.updateEmployee2(employee).toPromise();
                })
            );

            this._service.selectedItem().metadata;
            if (!this._service.selectedItem().metadata) {
                this._service.selectedItem().metadata = {};
            }
            this._service.selectedItem().metadata['employees'] = this.done;
            const root = this._service.findRootParentNode(this._service.selectedItem().id);

            this._service.updateItem(root ?? this._service.selectedItem());
        }
    }

    length = computed(() => this.todo().length);
    pageSize = signal(10);
    pageIndex = signal(0);
    pageSizeOptions = [5, 10, 25];

    hidePageSize = false;
    showPageSizeOptions = true;
    showFirstLastButtons = true;
    disabled = false;

    pageEvent: PageEvent;

    handlePageEvent(e: PageEvent) {
        this.pageEvent = e;
        this.pageSize.set(e.pageSize);
        this.pageIndex.set(e.pageIndex);
    }

    setPageSizeOptions(setPageSizeOptionsInput: string) {
        if (setPageSizeOptionsInput) {
            this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
        }
    }
}

@Component({
    selector: 'org-sys-area-editor',
    imports: [...commonImports],
    templateUrl: './org-sys-area-editor.component.html',
    styleUrl: './org-sys-area-editor.component.scss',
})
export class SysEditorComponent extends OrgSysAreaEditorComponent<System> {
    constructor(
        protected inject: Injector,
        private uploadFileService: UploadFileService
    ) {
        super(inject);

        this._service = this.inject.get(SysService);

        effect(
            () => {
                const showLogo: boolean =
                    !!this.selectedItem().version && !!this.selectedItem().versionId;

                this.showLogo.set(showLogo);
            },
            {
                allowSignalWrites: true,
            }
        );

        this.defaultConstructor();
    }

    constructModel() {
        let selectedSys = this.selectedItem();
        if (!selectedSys) return;

        let model: any = {
            title: selectedSys.title,
        };

        model.infoTypes = (selectedSys as OrganisationSubType).infoTypes;
        if (
            Object.keys(selectedSys).includes('version') ||
            Object.keys(selectedSys).includes('status')
        ) {
            model.version = (selectedSys as System).version;
            model.status = (selectedSys as System).status;
        }
        model.code = (selectedSys as System).code;

        return model;
    }

    protected override handleFileInput(files: FileList) {
        Array.from(files).forEach(file => {
            this.uploadFileService
                .backendApiUploadFilePost('thumbnail', file, 'PICTURE')
                .subscribe((data: ApiUploadFilePost200Response) => {
                    if (this.selectedItem().metadata) {
                        this.selectedItem().metadata['logo'] = data.url;
                    } else {
                        this.selectedItem().metadata = { logo: data.url };
                    }
                    const item = structuredClone(this.selectedItem());
                    item.id = item.id.split('_')[1];
                    this._service.updateItem(item);
                });
        });
    }
}
@Component({
    selector: 'org-sys-area-editor',
    imports: [...commonImports],
    templateUrl: './org-sys-area-editor.component.html',
    styleUrl: './org-sys-area-editor.component.scss',
})
export class AreaEditorComponent extends OrgSysAreaEditorComponent<Area> {
    private orgFilterService: OrganisationFilterService;

    override displayedColumns: Signal<TableColumnDef[]> = signal([
        {
            columnKey: 'title',
            columnName: this.translocoContent()['title'],
            contentType: 'text',
            visible: true,
        },
    ]);
    override dataSource: Signal<MatTableDataSource<any>>;

    constructor(
        protected inject: Injector,
        private saveButtonService: SaveButtonService
    ) {
        super(inject);

        this._service = this.inject.get(AreaService);
        this.orgFilterService = this.inject.get(OrganisationFilterService);

        this.defaultConstructor();

        this.dataSource = computed(
            () =>
                new MatTableDataSource(
                    this.orgFilterService
                        .organisations()
                        .filter(
                            org => org.organisationId !== '00000000-0000-0000-0000-000000000000'
                        )
                )
        );

        this.selection = computed(() =>
            this.orgFilterService
                .organisations()
                .filter(org => this.selectedItem().inheritors.includes(org.organisationId))
        );

        effect(
            () => {
                const showHeritance =
                    this.orgFilterService.selectedOrganisation() ===
                        '00000000-0000-0000-0000-000000000000' &&
                    this.selectedItem().status === 'ACTIVE';

                this.showHeritants.set(showHeritance);
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    constructModel() {
        let selectedArea = this.selectedItem();
        if (!selectedArea) return;

        let model: any = {
            title: selectedArea.title,
        };

        model.infoTypes = (selectedArea as OrganisationSubType).infoTypes;
        if (
            Object.keys(selectedArea).includes('version') ||
            Object.keys(selectedArea).includes('status')
        ) {
            model.version = (selectedArea as Area).version;
            model.status = (selectedArea as Area).status;
        }
        model.code = (selectedArea as Area).code;

        return model;
    }

    addAdditionalField(fields: FormlyFieldConfig[]): void {
        if (
            this.orgFilterService.selectedOrganisation() !==
                '00000000-0000-0000-0000-000000000000' &&
            this.selectedItem().status !== 'ACTIVE'
        )
            return;
        if (this.selectedItem().versionId && this.selectedItem().version) {
            // Select the organisations that inherit this area
            fields[0].fieldGroup.push({
                type: 'select',
                key: 'inheritance',
                name: 'inheritance',
                props: {
                    label: this._formService._translocoContent()['form.inheritance'],
                    multiple: true,
                    selectAllOption: this._formService._translocoContent()['selectAll'],
                    options: this.orgFilterService
                        .organisations()
                        .map(org => ({ value: org.organisationId, label: org.title })),
                },
            });
        }
    }

    override rowSelectionChange($event: Array<any>): void {
        this.selectedItem().inheritors = $event.map(org => org.organisationId);
        this.saveButtonService.hasUnsavedChanges = true;
    }

    override handleSelectedRows(selectedOrgs: any[]): void {}
}
